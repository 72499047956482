import React from "react";
import { TextAnimationUnderline } from "../text-animation-underline";
import { TextLink } from "../textlink";
import { Link } from "docz";
import Reveal from "react-reveal/Reveal";
import moment from "moment";

export const cardSwitcher = (card) => {
  const { cardType: type, image, featureCard, viewPort } = card;

  var prefix = "";
  if (card.__typename === "DatoCMS_PressReleaseRecord") prefix = "/press/";
  if (card.__typename === "DatoCMS_BlogArticleRecord") prefix = "/insights/";

  switch (type) {
    case "news":
      return {
        content: (
          <Reveal effect="react-reveal--visible" fraction={0.7}>
            <Link className="card__link" to={`${prefix}${card.slug}`}>
              {image && image.url && (
                <figure className="card__header">
                  {featureCard ? (
                    <img
                      src={`${image?.url}?fit=crop${
                        viewPort === "isDesktop"
                          ? "&w=1200&h=764"
                          : viewPort === "isMobile" && "&h=190"
                      }&fm=jpg`}
                      alt={image.alt || ""}
                    />
                  ) : (
                    <img
                      src={`${image?.url}?fit=crop&h=190&fm=jpg`}
                      alt={image.alt || ""}
                    />
                  )}
                </figure>
              )}
              <div className="card__body">
                <div>
                  <TextAnimationUnderline
                    textStyle="sub-copy-title"
                    text={card?.heading || card?.title}
                  />
                </div>
              </div>
              <div className="card__footer">
                <p>
                  {moment(card._publishedAt).format("MMM DD, YYYY")}
                  {card.category.title && " - " + card.category.title}
                </p>
              </div>
            </Link>
          </Reveal>
        ),
        modifier: "card--news",
      };
    case "products":
      return {
        content: (
          <Reveal effect="react-reveal--visible" fraction={0.7}>
            <div className="card__body">
              <h5 className="card__heading">{card?.heading}</h5>
              <p className="card__description">{card?.description}</p>
              <p className="card__text">{card?.body}</p>
            </div>
            {card.image && (
              <figure className="card__background-image">
                <img src={card.image.url + '?w=700&fm=jpg'} alt={card.image.alt || card?.heading} />
              </figure>
            )}
          </Reveal>
        ),
        modifier: `card--products card--products--${card.color}`,
      };
    case "default":
      return {
        content: (
          <>
            {card.image && (
              <figure className="card__header">
                <img src={card.image.url} alt={card.image.alt || card?.heading} />
              </figure>
            )}
            <div className="card__body">
              <h5 className="card__heading">{card?.heading}</h5>
              <p className="card__text">{card?.body}</p>
            </div>
            <div className="card__footer">
              {card?.externalLink && (
                <TextLink
                  text={card.externalLink?.title}
                  quiet
                  to={card.externalLink?.url}
                />
              )}
            </div>
          </>
        ),
        modifier: "card--default",
      };
    default:
      return false;
  }
};
