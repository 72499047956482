import React, {useState, useEffect, useRef} from 'react';
import SplitType from 'split-type';
import classNames from 'classnames';

export const TextAnimationUnderline = (props) => {
  const el = useRef(null);

  const _classNames = classNames({
    'text-animation-underline': true,
    [`as-${props.textStyle}`]: true
  });

  useEffect(() => {
    // This uses a timeout because there was an issue with splitting before the font was fully loaded and rendered

    setTimeout(() => {
      const text = new SplitType(el.current, { types: 'lines' });
      el.current.classList.add('text-animation-underline--parsed');
    }, 100)
    
  }, [el]);

  return(
    <span className={_classNames} ref={el}>{ props.text }</span>
  )
}
