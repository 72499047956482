import React from "react";
import classNames from "classnames";
import { TextLink } from "../textlink";
import { cardSwitcher } from "./helpers";

export const Card = (props) => {
  const { card } = props;
  const { content, modifier } = cardSwitcher(card);
  const _classNames = classNames({
    card: true,
    [modifier]: modifier,
  });
  return <div className={_classNames}>{content}</div>;
};
