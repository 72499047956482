import React, {useEffect} from 'react';
import classNames from 'classnames';
import { Button } from '../button';
import { HtmlText } from '../html-text';

export const CookieBanner = (props) => {

  const _classNames = classNames({
    'cookie-banner': true,
    'cookie-banner--colapsed': true
  });
  var cookieAccepted = false;
  let el;
  
  useEffect(() => {
    el = document.querySelector('.cookie-banner');
    if (typeof window !== 'undefined') {
      cookieAccepted = localStorage.getItem('accepted-cookie');
    }
    if(!cookieAccepted && el) {
      el.classList.remove('cookie-banner--colapsed')
    }
  }, [])

  const acceptCookieHandler = () => {
    localStorage.setItem('accepted-cookie', true);
    el.classList.add('cookie-banner--colapsed')
  }

  return(
    <div className={_classNames} >
      <div className="cookie-banner__container">
        <div className="cookie-banner__richtext cookie-banner__richtext--color-white">
          <HtmlText html={props.body} />
        </div>
        <div className="cookie-banner__cta"  onClick={acceptCookieHandler}>
          <Button text={props.buttonText} secondary />
        </div>
      </div>
    </div>
  )
}
