import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { Cards } from '../cards';


export const Blog = (props) => {
  const { articles } = props;

  const _classNames = classNames({
    'blog': true,
    'blog--all': !props.category,
    'blog--category': props.category
  });
  
  return(
    <section className={_classNames}>
      <div className="blog__filters">
        <div className="blog__wrapper">
          <h1 className="blog__title">Insights</h1>
          <div className="blog__filter-items">
            <Link to="/insights/" className={`blog__filter-item blog__filter-item--color-gray ${props.category ? "" : "blog__filter-item--selected" }`}>All</Link>
            <div className="blog__items-container">
              {props.categories.map((category, index) => <Link activeClassName="blog__filter-item--selected" to={`/insights/category/${category.slug}`} className="blog__filter-item blog__filter-item--color-gray">{category.title}</Link> )}
            </div>
          </div>
        </div>
      </div>
      <div className="blog__cards-container">
        <Cards cards={articles} colorMode={'light'} containerType={'news'} articles colorMode="grey-50"/>
      </div>
    </section>
  )
}
