import React from 'react';
import classNames from 'classnames';
import { Link } from 'docz';
import QuietLogo from './quiet_icon.inline.svg';
import PageArrow from './page_arrow.inline.svg';

export const TextLink = (props) => {

  const _classNames = classNames({
    'link': true,
    'link--page': props.page,
    'link--copy': props.copy,
    'link--card': props.card,
    'link--quiet': props.quiet,
    'link--dark': props.dark
  });

  return(
    <Link to={props.to} className={_classNames} >
      {props.page ? <PageArrow /> : ""} 
      <span>{props.text}</span>
      {props.quiet ? <QuietLogo /> : ""}
    </Link>
  )
}
